














import { Component, Vue, Watch } from 'vue-property-decorator'
import Tabs from '@/app/ui/components/Tabs/index.vue'
import { Route } from 'vue-router'
import rescheduleController from '@/app/ui/controllers/ReschedulePickupController'

@Component({
  components: {
    Tabs,
  },
})
export default class EditManageDataMaster extends Vue {
  rescheduleController = rescheduleController
  currentTab = 0
  currentPeriod = ''

  itemTabs = [
    { label: 'CRRNFD', value: 0 },
    { label: 'Jadwal Ulang Pick Up', value: 1 },
  ]

  created(): void {
    if(this.$route.fullPath.includes('reschedule') || this.$route.params.tab) {
      this.currentTab = 1
    }

    this.$router.push({ name: this.getTabRoute(this.currentTab) })
    rescheduleController.getCounter()
  }

  get totalStatusWaitingReschedule(): number {
    return rescheduleController.totalStatusWaiting
  }

  private onTabsClick(tab: number): void {
    if(tab !== this.currentTab) {
      this.currentTab = tab
      this.itemTabs.forEach(item => {
        if (item.value === this.currentTab) {
          this.$router.push({ name: this.getTabRoute(item.value) })
        }
      })
    }
  }

  private getTabRoute(key: number): string {
    if (key === 0) {
      return 'ListPickupCRRNFD'
    }
    if (key === 1) {
      return 'ListPickupCRRNFDReschedule'
    }
    return ''
  }

  @Watch('$route', { deep: true })
  watchRouter(value: Route): void {
    if (value.query?.fromSidebar || !value.fullPath.includes('reschedule')) {
      this.currentTab = 0
    }
  }
}
