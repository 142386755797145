


































































































































import dayjs from 'dayjs'
import { Component, Prop, Vue } from 'vue-property-decorator'
import dayjsDuration from 'dayjs/plugin/duration'
import { Utils } from '@/app/infrastructures/misc'
import Button from '@/app/ui/components/Button/index.vue'
import ModalConfirmation from '../../Modals/ModalConfirmation.vue'
import controller from '@/app/ui/controllers/PickupController'

interface Pickup {
  shipmentId: string
  groupId: string
  productType: string
  isOnepack: boolean
  pickup: {
    fullname: string
    phoneNumber: string
    latitude: number
    longitude: number
    address: string
  }
  courierPickupDetail: {
    courierId: string
    courierName: string
    courierTypeValid: string
    phoneNumber: string
    partnerName: string
  }
  createdAt: string
  history: {
    reason: string
  }
  shipmentType: string
}

dayjs.extend(dayjsDuration)

@Component({
  components: {
    Button,
    ModalConfirmation,
  },
})
export default class PickupItemCRRNFD extends Vue {
  @Prop({ required: true }) public no!: number
  @Prop({ required: true }) public data!: Pickup

  controller = controller

  modalCRRSRCVisible = false

  get date(): string {
    return Utils.formatDateWithIDLocale(this.data.createdAt, 'DD-MMM-YYYY')
  }

  get time(): string {
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(this.data.createdAt, 'HH:mm Z')
    )
  }

  get duration(): string {
    const duration = dayjs.duration(dayjs().diff(dayjs(this.data.createdAt)))

    const result = []

    //Get Days
    const days = Math.floor(duration.asDays())
    if (days) {
      result.push(`${days}d`)
    }

    //Get Hours
    const hours = duration.hours()
    if (hours) {
      result.push(`${hours}h`)
    }

    //Get Minutes
    const minutes = duration.minutes()
    result.push(`${minutes}m`)

    return result.join(', ')
  }

  get overSLA(): boolean {
    return dayjs().diff(dayjs(this.data.createdAt), 'hours') >= 48
  }

  get lastCourier(): string {
    const crr = this.data.courierPickupDetail
    if (!crr) return ''
    return `[${crr.courierId}] ${crr.courierName} (${crr.courierTypeValid}) ${crr.phoneNumber} • ${crr.partnerName}`
  }

  get isBosspack(): boolean {
    return this.data.productType === 'BOSSPACK'
  }

  get showCRRSRCButton(): boolean {
    return this.data.shipmentId.substr(0, 2) === 'C1'
  }

  public onForceCRRSRC(): void {
    this.modalCRRSRCVisible = false
    this.controller.forceCRRSRC(this.data.shipmentId)
  }
}
