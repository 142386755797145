






































































































import { Component, Vue } from 'vue-property-decorator'
import dayjs from 'dayjs'
import controller from '@/app/ui/controllers/PickupController'
import id from 'dayjs/locale/id'
import SearchIcon from "@/app/ui/assets/search_icon.vue"
import FlashlightDisabledIcon from "@/app/ui/assets/flashlight_disabled.vue"
import CloseIcon from "@/app/ui/assets/close_line.vue"
import CourierIcon from '@/app/ui/assets/menu_icon_driver.vue'
import BoxPickupIcon from '@/app/ui/assets/menu_icon_pickup.vue'
import CheckedIcon from '@/app/ui/assets/check_line.vue'
import Tabs from "@/app/ui/components/Tabs/index.vue"
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import { PickupSummary } from '@/domain/entities/Pickup'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import BikeIcon from '@/app/ui/assets/ics_o_bike_white.vue'

interface OptionsNumber {
  label?: string
  value?: number
}

interface OptionsString {
  label?: string
  value?: string
}

@Component({
  components: {
    Tabs,
    DropdownSelect,
    SearchIcon,
    FlashlightDisabledIcon,
    CloseIcon,
    CourierIcon,
    BoxPickupIcon,
    CheckedIcon,
    LoadingOverlay,
    BikeIcon
  },
})
export default class PickupItemSummary extends Vue {
  controller = controller
  currentTab = 0;
  parameters: OptionsString = {}

  created(): void {
    controller.getPickupSummary()
  }

  get itemTabs(): OptionsNumber[] {
    const items = [
      { label: 'Pick Up On Going', value: 0 },
      { label: 'Pickup History', value: 1 }
    ]
    return items
  }

  get filterOptions(): OptionsString[] {
    let rows = []
    for (let i = 0; i < 6; i ++) {
      const labelMonth = dayjs().locale(id).subtract(i, 'month').format("MMMM")
      const valueMonth = dayjs().locale(id).subtract(i, 'month').format('YYYY-MM-DD')
      const item = { label: labelMonth, value: valueMonth }
      rows.push(item)
    }
    return rows
  }

  get dataSummary(): PickupSummary {
    return controller.pickupSummary
  }

  get percentagePickup(): string {
    const crrdon = this.dataSummary.crrdon || 0
    const all = this.dataSummary.all || 0
    const percent = Math.round((crrdon / all) * 100)
    return (crrdon && all) ? `${percent}%`: '0%';
  }

  private createParams(date: string | undefined, getBy: 'day' | 'month') {
    const startDate = dayjs(date).locale(id).startOf(getBy).format('YYYY-MM-DDTHH:mm:ss[Z]')
    const endDate = dayjs(date).locale(id).endOf(getBy).format('YYYY-MM-DDTHH:mm:ss[Z]')
    return {
      startDate,
      endDate
    }
  }

  private onTabsClick(tab: number) {
    this.currentTab = tab
    this.parameters = this.filterOptions[0]
    if (tab === 0) {
      controller.getPickupSummary()
    } else if (tab === 1) {
      const params = this.createParams(this.parameters.value, 'month')
      controller.getPickupSummary(params)
    }
  }

  private onSelectMonth(selected: OptionsString) {
    const params = this.createParams(selected.value, 'month')
    controller.getPickupSummary(params)
  }
}
