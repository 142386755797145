





























































































import PaperPlanIcon from '@/app/ui/assets/paper_plane_icon.vue'
import ListIcon from '@/app/ui/assets/list_icon.vue'
import dayjs from 'dayjs'
import { Component, Prop, Vue } from 'vue-property-decorator'
import dayjsDuration from 'dayjs/plugin/duration'
import { Utils } from '@/app/infrastructures/misc'
import Button from '@/app/ui/components/Button/index.vue'

interface Pickup {
  bookingId: string
  doOriginPackageId: string
  flag: string
  shipmentId: string
  groupId: string
  productType: string
  isOnepack: boolean
  pickup: {
    fullname: string
    phoneNumber: string
    latitude: number
    longitude: number
    address: string
  }
  courierPickupDetail: {
    courierId: string
    courierName: string
    courierTypeValid: string
    phoneNumber: string
    partnerName: string
  }
  createdAt: string
  history: {
    reason: string
    createdAt: string,
    actorName: string
  }
  shipmentType: string
}

dayjs.extend(dayjsDuration)

@Component({
  components: {
    PaperPlanIcon,
    ListIcon,
    Button
  },
})
export default class PickupItemCRRDON extends Vue {
  @Prop({ required: true }) public no!: number
  @Prop({ required: true }) public data!: Pickup

  get createdDate(): string {
    return Utils.formatDateWithIDLocale(
      this.data.createdAt,
      'DD-MMM-YYYY',
    )
  }

  get createdTime(): string {
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(
        this.data.createdAt,
        'HH:mm Z'
      ),
    )
  }

  get updatedDate(): string {
    return Utils.formatDateWithIDLocale(
      this.data.history.createdAt,
      'DD-MMM-YYYY',
    )
  }

  get updatedTime(): string {
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(
        this.data.history.createdAt,
        'HH:mm Z'
      ),
    )
  }

  get overSLA(): boolean {
    return dayjs().diff(dayjs(this.data.createdAt), 'hours') >= 48
  }

  get hasCourierPickupDetail(): boolean {
    return !!this.data.courierPickupDetail
  }

  get courier(): string {
    if (this.hasCourierPickupDetail) {
      const courierPickupDetail = this.data.courierPickupDetail
      return `${courierPickupDetail?.partnerName} - ${courierPickupDetail?.courierName} (${courierPickupDetail?.phoneNumber})`
    }

    return this.data.history.actorName
  }

  get canBeCancelled(): boolean {
    return (
      this.data.shipmentId.substr(0, 2) === 'AG' ||
      this.data.shipmentId.substr(0, 3) === 'ARA' ||
      this.data.shipmentId.substr(0, 4) === 'GARA' ||
      this.data.shipmentId.substr(0, 3) === 'GAG' ||
      this.data.shipmentId.substr(0, 2) === 'C1'
    )
  }

  get isBosspack(): boolean {
    return this.data.productType === 'BOSSPACK'
  }
}
