var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pickup-item no-footer"},[_c('div',{staticClass:"pickup-grid crrotw"},[_c('div',{staticClass:"pickup-no text-center"},[_vm._v(_vm._s(_vm.no))]),_c('div',{staticClass:"pickup-order-id"},[_c('router-link',{staticClass:"text-blue-600 hover:text-blue-700",attrs:{"to":{
          name: 'PickupCRROTWDetail',
          params: { shipmentId: _vm.data.shipmentId },
        }}},[_vm._v(" "+_vm._s(_vm.data.shipmentId)+" ")]),(_vm.data.bookingId)?_c('div',[_vm._v(" "+_vm._s(_vm.data.bookingId)+" ")]):_vm._e(),(_vm.data.doOriginPackageId)?_c('div',{staticClass:"text-bgMatterhorn"},[_vm._v(" "+_vm._s(_vm.data.doOriginPackageId)+" ")]):_vm._e()],1),_c('div',{staticClass:"pickup-customer"},[_vm._v(_vm._s(_vm.data.pickup.fullname))]),_c('div',{staticClass:"pickup-phone"},[_vm._v(_vm._s(_vm.data.pickup.phoneNumber))]),_c('div',{staticClass:"pickup-status grid gap-1.5 auto-cols-max"},[_c('div',{staticClass:"pickup-status-item"},[_vm._v(_vm._s(_vm.data.flag))]),(_vm.overSLA)?_c('div',{staticClass:"pickup-status-item"},[_vm._v("Over SLA")]):_vm._e(),(_vm.isBosspack)?_c('div',{staticClass:"pickup-status-item bosspack"},[_vm._v(" Bosspack ")]):_vm._e(),(_vm.data.isOnepack)?_c('div',{staticClass:"pickup-status-item bosspack"},[_vm._v(" Onepack ")]):_vm._e()]),_c('div',{staticClass:"pickup-address"},[_c('a',{staticClass:"text-blue-600 hover:text-blue-700",attrs:{"href":("http://maps.google.com/?q=" + (_vm.data.pickup.latitude) + "," + (_vm.data.pickup.longitude)),"target":"_blank"}},[_vm._v(_vm._s(_vm.data.pickup.address))])]),_c('div',{staticClass:"pickup-courier"},[(_vm.hasCourierPickupDetail)?_c('a',{staticClass:"text-blue-600 hover:text-blue-700",attrs:{"href":("https://api.whatsapp.com/send?phone=" + (_vm.data.courierPickupDetail.phoneNumber) + "&text=Hi%20Bapak/Ibu,%20kami%20ingin%20menanyakan%20Shipment%20ID%20" + (_vm.data.shipmentId)),"target":"_blank"}},[_vm._v(_vm._s(_vm.courier))]):_c('span',[_vm._v(_vm._s(_vm.courier))])]),_c('div',{staticClass:"pickup-datetime"},[_c('div',[_vm._v(_vm._s(_vm.date))]),_c('div',[_vm._v(_vm._s(_vm.time))])]),_c('div',{staticClass:"pickup-duration"},[_vm._v(_vm._s(_vm.duration))]),_c('div',{staticClass:"picup-markasdone"},[_c('router-link',{staticClass:"markasdone",attrs:{"to":{
          name: 'PickupCRROTWMarkAsDone',
          params: {
            shipmentId: _vm.data.shipmentId,
            courierId: _vm.data.history.actorId,
          },
        }}},[_vm._v(" Mark as done ")]),(_vm.data.isDedicated)?_c('router-link',{staticClass:"forceCRRNFD",attrs:{"to":{
          name: 'PickupCRROTWForceCRRNFD',
          params: {
            shipmentId: _vm.data.shipmentId,
          },
        }}},[_vm._v(" Force CRRNFD ")]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }